import { storeToRefs } from 'pinia';

import { useOrdersStore } from '~/store/orders';
import { ORDER_WITH_ITEMS } from '~/support/fixtures';

export default defineNuxtRouteMiddleware(to => {
  const store = useOrdersStore();
  const { currentOrder } = storeToRefs(store);

  if (Object.hasOwn(to.query, 'fake')) {
    currentOrder.value = ORDER_WITH_ITEMS;
  }

  if (to.path === '/start') {
    return store.reset();
  }

  if (!currentOrder.value && !(to.path.includes('/view') || to.path.includes('/print'))) {
    return navigateTo('/start');
  }
});
